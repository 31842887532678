import ReactGA from "react-ga4"

const analyticsTags = {
    es: {
        web: "G-P810SXJMTJ",
        ios: "G-NR0JEGPGV0",
        android: "G-1FS98WNWK3"
    },
    pt: {
        web: "G-477TTQV7LP",
        ios: "G-L7SBXDPSTK",
        android: "G-ME6SEDTEKH"
    }
}


export function initAnalytics (locale, platform) {
    console.log("initAnalytics", locale, platform)
    ReactGA.initialize(analyticsTags[locale][platform]);
}
