import { Routes } from "../Routes";
import ReactGA from "react-ga4";
import {useEffect} from "react";
import {initAnalytics} from "../analytics";




const Main = ()=> {


  useEffect(() => {
    let platform = 'web';
    if (window?.ReactNativeWebView?.injectedObjectJson()) {
        platform = JSON.parse(window?.ReactNativeWebView?.injectedObjectJson()).platform;
    }
    let urlLocale = window.location.search.split('=')[1];
    if(urlLocale){
      urlLocale = urlLocale.split('_')[0];
    }

    initAnalytics(urlLocale, platform);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);


  useEffect(() => {

  }, []);

  return (
      <Routes />
  );
}

export default Main;
