import Snippets from './Snippets'
import { APP_GTAG_SCRIPT_CLASS_NAME, APP_LANGUAGE, APP_SUBJECT, APP_INTENDEDAUDIENCE } from '../constants'

const TagManager = {
  dataScript: function (dataLayer) {
    const script = document.createElement('script')
    script.innerHTML = dataLayer
    return script
  },
  gtm: function (args) {
    const snippets = Snippets.tags(args)

    const noScript = () => {
      const noscript = document.createElement('noscript')
      noscript.innerHTML = snippets.iframe
      return noscript
    }

    const script = () => {
      const script = document.createElement('script')
      script.type = 'text/plain'
      script.setAttribute('class', APP_GTAG_SCRIPT_CLASS_NAME);
      script.innerHTML = snippets.script
      return script
    }

    const dataScript = this.dataScript(snippets.dataLayerVar)

    const dataLayerScript = () => {
      const scriptTag = document.createElement('script')
      scriptTag.innerHTML = `dataLayer: [{'language': '${APP_LANGUAGE}', 'subject': '${APP_SUBJECT}', 'intendedAudience': '${APP_INTENDEDAUDIENCE}' }];`;
      return scriptTag
    }

    return {
      noScript,
      script,
      dataScript,
      dataLayerScript
    }
  },
  initialize: function ({ gtmId, events = {}, dataLayer, dataLayerName = 'dataLayer', auth = '', preview = '' }) {
    const gtm = this.gtm({
      id: gtmId,
      events: events,
      dataLayer: dataLayer || undefined,
      dataLayerName: dataLayerName,
      auth,
      preview
    })
    if (dataLayer) document.head.appendChild(gtm.dataScript)
    //document.head.insertBefore(gtm.dataLayerScript(), document.head.childNodes[0])
    //document.head.insertBefore(gtm.script(), document.head.childNodes[0])
    //document.body.insertBefore(gtm.noScript(), document.body.childNodes[0])
  },
  dataLayer: function ({dataLayer, dataLayerName = 'dataLayer'}) {
    if (window[dataLayerName]) return window[dataLayerName].push(dataLayer)
    const snippets = Snippets.dataLayer(dataLayer, dataLayerName)
    const dataScript = this.dataScript(snippets)
    document.head.insertBefore(dataScript, document.head.childNodes[0])
  }
}

export default TagManager
